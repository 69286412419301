.tippy-popper {
  pointer-events: unset; }
  .tippy-popper .tippy-tooltip {
    padding: 20px;
    box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.17);
    border-radius: 15px;
    text-align: left; }
    .tippy-popper .tippy-tooltip.dark-theme {
      background-color: var(--bg-color-text-input); }
      .tippy-popper .tippy-tooltip.dark-theme a {
        color: var(--brand-blue);
        text-decoration: none; }
    .tippy-popper .tippy-tooltip.light-theme {
      background-color: var(--bg-color-text-input); }
      .tippy-popper .tippy-tooltip.light-theme a {
        color: var(--brand-blue);
        text-decoration: none; }
  .tippy-popper[x-placement^='right'] .tippy-tooltip.dark-theme .tippy-arrow {
    border-right-color: var(--bg-color-text-input); }
  .tippy-popper[x-placement^='right'] .tippy-tooltip.light-theme .tippy-arrow {
    border-right-color: var(--bg-color-text-input); }
  .tippy-popper[x-placement^='left'] .tippy-tooltip.dark-theme .tippy-arrow {
    border-left-color: var(--bg-color-text-input); }
  .tippy-popper[x-placement^='left'] .tippy-tooltip.light-theme .tippy-arrow {
    border-left-color: var(--bg-color-text-input); }
  .tippy-popper[x-placement^='top'] .tippy-tooltip.dark-theme .tippy-arrow {
    border-top-color: var(--bg-color-text-input); }
  .tippy-popper[x-placement^='top'] .tippy-tooltip.light-theme .tippy-arrow {
    border-top-color: var(--bg-color-text-input); }
  .tippy-popper[x-placement^='bottom'] .tippy-tooltip.dark-theme .tippy-arrow {
    border-bottom-color: var(--bg-color-text-input); }
  .tippy-popper[x-placement^='bottom'] .tippy-tooltip.light-theme .tippy-arrow {
    border-bottom-color: var(--bg-color-text-input); }

.odometer.odometer-auto-theme,
.odometer.odometer-theme-default {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative;
}
.odometer.odometer-auto-theme .odometer-digit,
.odometer.odometer-theme-default .odometer-digit {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer,
.odometer.odometer-theme-default .odometer-digit .odometer-digit-spacer {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  visibility: hidden;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner,
.odometer.odometer-theme-default .odometer-digit .odometer-digit-inner {
  text-align: left;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon,
.odometer.odometer-theme-default .odometer-digit .odometer-ribbon {
  display: block;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon-inner,
.odometer.odometer-theme-default .odometer-digit .odometer-ribbon-inner {
  display: block;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-value,
.odometer.odometer-theme-default .odometer-digit .odometer-value {
  display: block;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
}
.odometer.odometer-auto-theme .odometer-digit .odometer-value.odometer-last-value,
.odometer.odometer-theme-default .odometer-digit .odometer-value.odometer-last-value {
  position: absolute;
}
.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner,
.odometer.odometer-theme-default.odometer-animating-up .odometer-ribbon-inner {
  -webkit-transition: -webkit-transform 2s;
  transition: -webkit-transform 2s;
  transition: transform 2s;
  transition: transform 2s, -webkit-transform 2s;
}
.odometer.odometer-auto-theme.odometer-animating-up.odometer-animating .odometer-ribbon-inner,
.odometer.odometer-theme-default.odometer-animating-up.odometer-animating .odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
}
.odometer.odometer-auto-theme.odometer-animating-down .odometer-ribbon-inner,
.odometer.odometer-theme-default.odometer-animating-down .odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
}
.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating .odometer-ribbon-inner,
.odometer.odometer-theme-default.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
  -webkit-transition: -webkit-transform 2s;
  transition: -webkit-transform 2s;
  transition: transform 2s;
  transition: transform 2s, -webkit-transform 2s;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.odometer.odometer-auto-theme .odometer-value,
.odometer.odometer-theme-default .odometer-value {
  text-align: center;
}

.react-tags {
  position: relative;
  height: 200px;
  text-align: left;
  font-size: 13px;
  line-height: 1;
  cursor: text; }
  .react-tags.is-focused {
    border-color: #b1b1b1; }
  .react-tags__selected {
    grid-gap: 6px;
    gap: 6px; }
  .react-tags__selected-tag {
    display: inline-flex;
    align-items: center;
    margin: 0 15px 10px 0;
    padding: 4px 4px 4px 13px;
    background-color: var(--green);
    border-radius: 100px;
    border: 0;
    cursor: pointer;
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease;
    word-break: break-word;
    text-align: left;
    font-family: var(--font-google-cdn);
    font-size: 10px;
    font-weight: 500;
    color: var(--text-color-white); }
    .react-tags__selected-tag:after {
      content: '';
      display: inline-block;
      min-width: 18px;
      min-height: 18px;
      background-color: var(--text-color-white);
      color: var(--text-color-dark-gray);
      margin-left: 12px;
      border-radius: 100%;
      opacity: .6;
      -webkit-mask-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' xmlns:svg='http://www.w3.org/2000/svg'%3E%3Cpath d='M 9 0 A 9 9 0 0 0 0 9 A 9 9 0 0 0 9 18 A 9 9 0 0 0 18 9 A 9 9 0 0 0 9 0 z M 6.3867188 5.4550781 C 6.5241686 5.4564231 6.6589564 5.4845526 6.7851562 5.5390625 C 6.9113461 5.5935724 7.0258838 5.6723295 7.1210938 5.7714844 L 8.9277344 7.5800781 L 10.736328 5.7734375 C 10.931398 5.5848247 11.191561 5.4801079 11.462891 5.4824219 C 11.73423 5.4847369 11.993727 5.5932424 12.185547 5.7851562 C 12.377367 5.9770701 12.486101 6.2384359 12.488281 6.5097656 C 12.490471 6.7810954 12.385975 7.0413483 12.197266 7.2363281 L 10.392578 9.0410156 L 12.195312 10.845703 C 12.294282 10.940983 12.373364 11.053528 12.427734 11.179688 C 12.482104 11.305847 12.510419 11.442708 12.511719 11.580078 C 12.513029 11.717448 12.487517 11.853299 12.435547 11.980469 C 12.383577 12.107639 12.306134 12.223173 12.208984 12.320312 C 12.111844 12.417452 11.99631 12.494905 11.869141 12.546875 C 11.741981 12.598845 11.60612 12.624347 11.46875 12.623047 C 11.33138 12.621747 11.196482 12.593432 11.070312 12.539062 C 10.944153 12.484693 10.829665 12.405601 10.734375 12.306641 L 8.9277344 10.5 L 7.125 12.308594 C 6.9299402 12.497204 6.6697672 12.601909 6.3984375 12.599609 C 6.1271078 12.597299 5.8676011 12.488785 5.6757812 12.296875 C 5.4839604 12.104955 5.3752269 11.845558 5.3730469 11.574219 C 5.3708649 11.302889 5.4753537 11.040683 5.6640625 10.845703 L 7.46875 9.0410156 L 5.6621094 7.234375 C 5.5628145 7.1393151 5.4843814 7.0245574 5.4296875 6.8984375 C 5.3749926 6.7723276 5.345297 6.6374499 5.34375 6.5 C 5.342204 6.3625401 5.3680789 6.2249661 5.4199219 6.0976562 C 5.4717658 5.9703374 5.5474003 5.8550854 5.6445312 5.7578125 C 5.7416622 5.6605386 5.8590903 5.5832799 5.9863281 5.53125 C 6.113561 5.4792201 6.2492589 5.4537341 6.3867188 5.4550781 z ' fill='currentColor'/%3E%3C/svg%3E");
              mask-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' xmlns:svg='http://www.w3.org/2000/svg'%3E%3Cpath d='M 9 0 A 9 9 0 0 0 0 9 A 9 9 0 0 0 9 18 A 9 9 0 0 0 18 9 A 9 9 0 0 0 9 0 z M 6.3867188 5.4550781 C 6.5241686 5.4564231 6.6589564 5.4845526 6.7851562 5.5390625 C 6.9113461 5.5935724 7.0258838 5.6723295 7.1210938 5.7714844 L 8.9277344 7.5800781 L 10.736328 5.7734375 C 10.931398 5.5848247 11.191561 5.4801079 11.462891 5.4824219 C 11.73423 5.4847369 11.993727 5.5932424 12.185547 5.7851562 C 12.377367 5.9770701 12.486101 6.2384359 12.488281 6.5097656 C 12.490471 6.7810954 12.385975 7.0413483 12.197266 7.2363281 L 10.392578 9.0410156 L 12.195312 10.845703 C 12.294282 10.940983 12.373364 11.053528 12.427734 11.179688 C 12.482104 11.305847 12.510419 11.442708 12.511719 11.580078 C 12.513029 11.717448 12.487517 11.853299 12.435547 11.980469 C 12.383577 12.107639 12.306134 12.223173 12.208984 12.320312 C 12.111844 12.417452 11.99631 12.494905 11.869141 12.546875 C 11.741981 12.598845 11.60612 12.624347 11.46875 12.623047 C 11.33138 12.621747 11.196482 12.593432 11.070312 12.539062 C 10.944153 12.484693 10.829665 12.405601 10.734375 12.306641 L 8.9277344 10.5 L 7.125 12.308594 C 6.9299402 12.497204 6.6697672 12.601909 6.3984375 12.599609 C 6.1271078 12.597299 5.8676011 12.488785 5.6757812 12.296875 C 5.4839604 12.104955 5.3752269 11.845558 5.3730469 11.574219 C 5.3708649 11.302889 5.4753537 11.040683 5.6640625 10.845703 L 7.46875 9.0410156 L 5.6621094 7.234375 C 5.5628145 7.1393151 5.4843814 7.0245574 5.4296875 6.8984375 C 5.3749926 6.7723276 5.345297 6.6374499 5.34375 6.5 C 5.342204 6.3625401 5.3680789 6.2249661 5.4199219 6.0976562 C 5.4717658 5.9703374 5.5474003 5.8550854 5.6445312 5.7578125 C 5.7416622 5.6605386 5.8590903 5.5832799 5.9863281 5.53125 C 6.113561 5.4792201 6.2492589 5.4537341 6.3867188 5.4550781 z ' fill='currentColor'/%3E%3C/svg%3E");
      -webkit-mask-repeat: no-repeat;
              mask-repeat: no-repeat;
      -webkit-mask-position: center;
              mask-position: center; }
    .react-tags__selected-tag:hover, .react-tags__selected-tag:focus {
      background-color: var(--green-secondary); }
  .react-tags__search {
    display: inline-block;
    padding: 7px 2px;
    margin-bottom: 6px;
    max-width: 100%; }
    @media screen and (min-width: 30em) {
      .react-tags__search {
        position: relative; } }
    .react-tags__search input {
      max-width: 100%;
      margin: 0;
      padding: 0;
      border: 0;
      outline: none;
      font-size: inherit;
      line-height: inherit; }
  .react-tags__suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%; }
    @media screen and (min-width: 30em) {
      .react-tags__suggestions {
        width: 240px; } }
    .react-tags__suggestions ul {
      margin: 4px -1px;
      padding: 0;
      list-style: none;
      background: white;
      border: 1px solid #d1d1d1;
      border-radius: 2px;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2); }
    .react-tags__suggestions li {
      border-bottom: 1px solid #ddd;
      padding: 6px 8px; }
      .react-tags__suggestions li.is-active {
        background: #b7cfe0; }
      .react-tags__suggestions li.is-disabled {
        opacity: 0.5;
        cursor: auto; }
      .react-tags__suggestions li mark {
        text-decoration: underline;
        background: none;
        font-weight: 600; }
      .react-tags__suggestions li:hover {
        cursor: pointer;
        background: #eee; }

/*!
 * https://github.com/YouCanBookMe/react-datetime
 */
.rdt {
  position: relative; }

.rdtPicker {
  display: none;
  position: absolute;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); }

.rdtOpen .rdtPicker {
  display: block; }

.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static; }

.rdtPicker .rdtTimeToggle {
  text-align: center; }

.rdtPicker table {
  width: 100%;
  margin: 0; }

.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 28px; }

.rdtPicker td {
  cursor: pointer; }

.rdtTimeToggle {
  font-size: 1.5rem; }

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: var(--bg-color-white-gray);
  cursor: pointer; }

.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: var(--text-color-white-gray); }

.rdtPicker td.rdtToday {
  position: relative; }

.rdtPicker td.rdtToday:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid var(--green);
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px; }

.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: var(--green);
  color: #fff; }

.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff; }

.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: var(--text-color-white-gray);
  cursor: not-allowed; }

.rdtPicker td span.rdtOld {
  color: var(--text-color-white-gray); }

.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: var(--text-color-white-gray);
  cursor: not-allowed; }

.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
  cursor: default; }

.rdtPicker th.rdtSwitch {
  width: 100px; }

.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top; }

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: var(--text-color-white-gray);
  cursor: not-allowed; }

.rdtPicker thead tr:first-child th {
  cursor: pointer; }

.rdtPicker thead tr:first-child th:hover {
  background: var(--bg-color-white-gray); }

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer; }

.rdtPicker button:hover {
  background-color: var(--bg-color-white-gray); }

.rdtPicker thead button {
  width: 100%;
  height: 100%; }

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer; }

td.rdtMonth:hover,
td.rdtYear:hover {
  background: var(--bg-color-white-gray); }

.rdtCounters {
  display: inline-block; }

.rdtCounters > div {
  float: left; }

.rdtCounter {
  height: 100px; }

.rdtCounter {
  width: 40px; }

.rdtCounterSeparator {
  line-height: 100px; }

.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.rdtCounter .rdtBtn:hover {
  background: var(--bg-color-white-gray); }

.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em; }

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px; }

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px; }

.rdtTime td {
  cursor: default; }

.react-contexify {
  z-index: 150;
  background-color: var(--white-box-background);
  border: none;
  border-radius: 10px;
  color: var(--text-color);
  box-shadow: inset 1px 1px 1px 1px color-mix(in srgb, var(--border-color) 15%, transparent);
  padding: 6px; }
  .react-contexify > div {
    display: flex;
    flex-direction: column;
    grid-row-gap: 8px;
    row-gap: 8px; }

.react-contexify__item__content {
  position: relative;
  width: 100%;
  padding: 8px 36px 8px 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-column-gap: 8px;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
  border: none;
  background-color: transparent;
  color: var(--text-color);
  cursor: pointer;
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
  font-size: 12px;
  text-align: left;
  border-radius: 14px; }
  .react-contexify__item__content > span {
    color: var(--text-grey);
    text-transform: uppercase; }
  .react-contexify__item__content > span, .react-contexify__item__content::before {
    -webkit-transition: 0.2s ease;
    transition: 0.2s ease; }

.react-contexify__item:not(.react-contexify__item--disabled):hover > .react-contexify__item__content {
  background-color: var(--dark-box-background);
  font-weight: 600; }

.react-contexify__item.react-contexify__item--disabled {
  opacity: 1; }

.react-contexify__separator {
  float: none;
  background: none;
  border: 0;
  margin: 0;
  height: 0;
  padding: 12.5px 0; }

.react-contexify__submenu {
  padding: 6px;
  display: flex;
  flex-direction: column;
  grid-row-gap: 8px;
  row-gap: 8px; }
  .react-contexify__submenu::before {
    content: '';
    position: absolute;
    width: calc(100% + 30px);
    height: calc(100% + 30px);
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background: transparent; }

.react-contexify__submenu-arrow {
  font-size: 0;
  text-indent: -9999em; }
  .react-contexify__submenu-arrow::before {
    position: relative;
    top: 6px;
    display: inline-block;
    width: 6px;
    height: 11px;
    background-color: currentColor;
    color: var(--text-grey);
    content: "";
    -webkit-mask-image: url("data:image/svg+xml,%3Csvg width='6' height='11' viewBox='0 0 6 11' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.000106877 9.84808C-0.0023434 9.54601 0.104892 9.25425 0.300097 9.03188L3.48524 5.49687L0.303847 1.96815C0.108466 1.74607 -4.1722e-07 1.45513 -4.3042e-07 1.15314C-4.43621e-07 0.851141 0.108466 0.5602 0.303847 0.33812C0.397413 0.231536 0.511079 0.146452 0.637655 0.0882511C0.764232 0.0300502 0.900962 -3.93823e-08 1.0392 -4.54248e-08C1.17743 -5.14673e-08 1.31416 0.0300502 1.44074 0.0882511C1.56732 0.146452 1.68098 0.231536 1.77455 0.33812L5.69467 4.68618C5.89093 4.90749 6 5.19836 6 5.50041C6 5.80246 5.89093 6.09333 5.69467 6.31464L1.77455 10.6611C1.6811 10.7679 1.56748 10.8532 1.44088 10.9115C1.31429 10.9699 1.1775 11 1.0392 11C0.900895 11 0.764104 10.9699 0.637511 10.9115C0.510919 10.8532 0.397292 10.7679 0.303847 10.6611C0.107598 10.4404 -0.00101326 10.1497 0.000106877 9.84808Z' fill='currentColor'/%3E%3C/svg%3E");
            mask-image: url("data:image/svg+xml,%3Csvg width='6' height='11' viewBox='0 0 6 11' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.000106877 9.84808C-0.0023434 9.54601 0.104892 9.25425 0.300097 9.03188L3.48524 5.49687L0.303847 1.96815C0.108466 1.74607 -4.1722e-07 1.45513 -4.3042e-07 1.15314C-4.43621e-07 0.851141 0.108466 0.5602 0.303847 0.33812C0.397413 0.231536 0.511079 0.146452 0.637655 0.0882511C0.764232 0.0300502 0.900962 -3.93823e-08 1.0392 -4.54248e-08C1.17743 -5.14673e-08 1.31416 0.0300502 1.44074 0.0882511C1.56732 0.146452 1.68098 0.231536 1.77455 0.33812L5.69467 4.68618C5.89093 4.90749 6 5.19836 6 5.50041C6 5.80246 5.89093 6.09333 5.69467 6.31464L1.77455 10.6611C1.6811 10.7679 1.56748 10.8532 1.44088 10.9115C1.31429 10.9699 1.1775 11 1.0392 11C0.900895 11 0.764104 10.9699 0.637511 10.9115C0.510919 10.8532 0.397292 10.7679 0.303847 10.6611C0.107598 10.4404 -0.00101326 10.1497 0.000106877 9.84808Z' fill='currentColor'/%3E%3C/svg%3E");
    -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
    -webkit-mask-position: center;
            mask-position: center; }

