.react-contexify {
  z-index: 150;
  background-color: var(--white-box-background);
  border: none;
  border-radius: 10px;
  color: var(--text-color);
  box-shadow: inset 1px 1px 1px 1px color-mix(in srgb, var(--border-color) 15%, transparent);
  padding: 6px;
  > div {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
}

.react-contexify__item {
  &__content {
    position: relative;
    width: 100%;
    padding: 8px 36px 8px 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 8px;
    border: none;
    background-color: transparent;
    color: var(--text-color);
    cursor: pointer;
    transition: 0.2s ease;
    font-size: 12px;
    text-align: left;
    border-radius: 14px;

    > span {
      color: var(--text-grey);
      text-transform: uppercase;
    }

    > span,
    &::before {
      transition: 0.2s ease;
    }
  }

  &:not(.react-contexify__item--disabled) {
    &:hover > .react-contexify__item__content {
      background-color: var(--dark-box-background);
      font-weight: 600;
    }
  }
}

.react-contexify__item.react-contexify__item--disabled {
  opacity: 1;
}

.react-contexify__separator {
  float: none;
  background: none;

  border: 0;
  margin: 0;
  height: 0;

  padding: 12.5px 0;
}

.react-contexify__submenu {
  padding: 6px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  &::before {
    content: '';
    position: absolute;
    width: calc(100% + 30px);
    height: calc(100% + 30px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: transparent;
  }
}

.react-contexify__submenu-arrow {
  font-size: 0;
  text-indent: -9999em;

  &::before {
    position: relative;
    top: 6px;

    display: inline-block;
    width: 6px;
    height: 11px;
    background-color: currentColor;
    color: var(--text-grey);
    content: "";

    mask-image: url("data:image/svg+xml,%3Csvg width='6' height='11' viewBox='0 0 6 11' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.000106877 9.84808C-0.0023434 9.54601 0.104892 9.25425 0.300097 9.03188L3.48524 5.49687L0.303847 1.96815C0.108466 1.74607 -4.1722e-07 1.45513 -4.3042e-07 1.15314C-4.43621e-07 0.851141 0.108466 0.5602 0.303847 0.33812C0.397413 0.231536 0.511079 0.146452 0.637655 0.0882511C0.764232 0.0300502 0.900962 -3.93823e-08 1.0392 -4.54248e-08C1.17743 -5.14673e-08 1.31416 0.0300502 1.44074 0.0882511C1.56732 0.146452 1.68098 0.231536 1.77455 0.33812L5.69467 4.68618C5.89093 4.90749 6 5.19836 6 5.50041C6 5.80246 5.89093 6.09333 5.69467 6.31464L1.77455 10.6611C1.6811 10.7679 1.56748 10.8532 1.44088 10.9115C1.31429 10.9699 1.1775 11 1.0392 11C0.900895 11 0.764104 10.9699 0.637511 10.9115C0.510919 10.8532 0.397292 10.7679 0.303847 10.6611C0.107598 10.4404 -0.00101326 10.1497 0.000106877 9.84808Z' fill='currentColor'/%3E%3C/svg%3E");
    mask-repeat: no-repeat;
    mask-position: center;
  }
}
