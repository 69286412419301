.tippy-popper {
  pointer-events: unset;

  .tippy-tooltip {
    padding: 20px;
    box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.17);
    border-radius: 15px;
    text-align: left;

    &.dark-theme {
      background-color: var(--bg-color-text-input);

      a {
        color: var(--brand-blue);
        text-decoration: none;
      }
    }

    &.light-theme {
      background-color: var(--bg-color-text-input);

      a {
        color: var(--brand-blue);
        text-decoration: none;
      }
    }
  }

  &[x-placement^='right'] {
    .tippy-tooltip {
      &.dark-theme {
        .tippy-arrow {
          border-right-color: var(--bg-color-text-input);
        }
      }
      &.light-theme {
        .tippy-arrow {
          border-right-color: var(--bg-color-text-input);
        }
      }
    }
  }

  &[x-placement^='left'] {
    .tippy-tooltip {
      &.dark-theme {
        .tippy-arrow {
          border-left-color: var(--bg-color-text-input);
        }
      }
      &.light-theme {
        .tippy-arrow {
          border-left-color: var(--bg-color-text-input);
        }
      }
    }
  }

  &[x-placement^='top'] {
    .tippy-tooltip {
      &.dark-theme {
        .tippy-arrow {
          border-top-color: var(--bg-color-text-input);
        }
      }
      &.light-theme {
        .tippy-arrow {
          border-top-color: var(--bg-color-text-input);
        }
      }
    }
  }

  &[x-placement^='bottom'] {
    .tippy-tooltip {
      &.dark-theme {
        .tippy-arrow {
          border-bottom-color: var(--bg-color-text-input);
        }
      }
      &.light-theme {
        .tippy-arrow {
          border-bottom-color: var(--bg-color-text-input);
        }
      }
    }
  }
}
