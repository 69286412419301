.react-tags {
  position: relative;
  height: 200px;
  text-align: left;

  font-size: 13px;
  line-height: 1;

  cursor: text;

  &.is-focused {
    border-color: #b1b1b1;
  }

  &__selected {
    gap: 6px;
  }

  &__selected-tag {
    display: inline-flex;
    align-items: center;
    margin: 0 15px 10px 0;
    padding: 4px 4px 4px 13px;
    background-color: var(--green);
    border-radius: 100px;
    border: 0;
    cursor: pointer;
    transition: 0.3s ease;
    word-break: break-word;
    text-align: left;

    font-family: var(--font-google-cdn);
    font-size: 10px;
    font-weight: 500;
    color: var(--text-color-white);

    &:after {
      content: '';
      display: inline-block;
      min-width: 18px;
      min-height: 18px;
      background-color: var(--text-color-white);
      color: var(--text-color-dark-gray);
      margin-left: 12px;
      border-radius: 100%;
      opacity: .6;

      mask-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' xmlns:svg='http://www.w3.org/2000/svg'%3E%3Cpath d='M 9 0 A 9 9 0 0 0 0 9 A 9 9 0 0 0 9 18 A 9 9 0 0 0 18 9 A 9 9 0 0 0 9 0 z M 6.3867188 5.4550781 C 6.5241686 5.4564231 6.6589564 5.4845526 6.7851562 5.5390625 C 6.9113461 5.5935724 7.0258838 5.6723295 7.1210938 5.7714844 L 8.9277344 7.5800781 L 10.736328 5.7734375 C 10.931398 5.5848247 11.191561 5.4801079 11.462891 5.4824219 C 11.73423 5.4847369 11.993727 5.5932424 12.185547 5.7851562 C 12.377367 5.9770701 12.486101 6.2384359 12.488281 6.5097656 C 12.490471 6.7810954 12.385975 7.0413483 12.197266 7.2363281 L 10.392578 9.0410156 L 12.195312 10.845703 C 12.294282 10.940983 12.373364 11.053528 12.427734 11.179688 C 12.482104 11.305847 12.510419 11.442708 12.511719 11.580078 C 12.513029 11.717448 12.487517 11.853299 12.435547 11.980469 C 12.383577 12.107639 12.306134 12.223173 12.208984 12.320312 C 12.111844 12.417452 11.99631 12.494905 11.869141 12.546875 C 11.741981 12.598845 11.60612 12.624347 11.46875 12.623047 C 11.33138 12.621747 11.196482 12.593432 11.070312 12.539062 C 10.944153 12.484693 10.829665 12.405601 10.734375 12.306641 L 8.9277344 10.5 L 7.125 12.308594 C 6.9299402 12.497204 6.6697672 12.601909 6.3984375 12.599609 C 6.1271078 12.597299 5.8676011 12.488785 5.6757812 12.296875 C 5.4839604 12.104955 5.3752269 11.845558 5.3730469 11.574219 C 5.3708649 11.302889 5.4753537 11.040683 5.6640625 10.845703 L 7.46875 9.0410156 L 5.6621094 7.234375 C 5.5628145 7.1393151 5.4843814 7.0245574 5.4296875 6.8984375 C 5.3749926 6.7723276 5.345297 6.6374499 5.34375 6.5 C 5.342204 6.3625401 5.3680789 6.2249661 5.4199219 6.0976562 C 5.4717658 5.9703374 5.5474003 5.8550854 5.6445312 5.7578125 C 5.7416622 5.6605386 5.8590903 5.5832799 5.9863281 5.53125 C 6.113561 5.4792201 6.2492589 5.4537341 6.3867188 5.4550781 z ' fill='currentColor'/%3E%3C/svg%3E");
      mask-repeat: no-repeat;
      mask-position: center;
    }

    &:hover,
    &:focus {
      background-color: var(--green-secondary);
    }
  }

  &__search {
    display: inline-block;
    padding: 7px 2px;
    margin-bottom: 6px;
    max-width: 100%;

    @media screen and (min-width: 30em) {
      position: relative;
    }

    input {
      max-width: 100%;
      margin: 0;
      padding: 0;
      border: 0;
      outline: none;
      font-size: inherit;
      line-height: inherit;
    }
  }

  &__suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;

    @media screen and (min-width: 30em) {
      width: 240px;
    }

    ul {
      margin: 4px -1px;
      padding: 0;
      list-style: none;
      background: white;
      border: 1px solid #d1d1d1;
      border-radius: 2px;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    }

    li {
      border-bottom: 1px solid #ddd;
      padding: 6px 8px;

      &.is-active {
        background: #b7cfe0;
      }

      &.is-disabled {
        opacity: 0.5;
        cursor: auto;
      }

      mark {
        text-decoration: underline;
        background: none;
        font-weight: 600;
      }

      &:hover {
        cursor: pointer;
        background: #eee;
      }
    }
  }
}
